class Utils {
    isTouchEnabled() {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0);
    }

    isHomepage() {
        return document.body.dataset.isHomepage;
    }
}

export let utils = new Utils();