//import $ from 'jquery';
//import './lib/foundation-explicit-pieces';

import 'what-input';
import {dom, library, config} from '@fortawesome/fontawesome-svg-core';
import { faCalendar} from '@fortawesome/free-regular-svg-icons/faCalendar';
import { faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import { faBullseye } from '@fortawesome/free-solid-svg-icons/faBullseye';
import { faUsersViewfinder } from '@fortawesome/free-solid-svg-icons/faUsersViewfinder';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose';
import { faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faFolderClosed } from '@fortawesome/free-regular-svg-icons/faFolderClosed';

import {foaTabs} from './components/foaTabs';
import {mobileMenu} from './components/mobileMenu';
import {announcement} from './components/announcement';

import '../sass/style.scss';

//window.jQuery = $;
//window.jQuery = jQuery;

const onLoad = () => {
    config.observeMutations = false;
    library.add(faUser, faClose, faBars, faBullseye, faCalendar, faUsersViewfinder, faYoutube, faInstagram, faFilePdf, faFolderClosed);
    dom.watch();
    //$(document).foundation();
    foaTabs.addEventListenersToFieldsOfAction();
    mobileMenu.addEventListenersToMobileMenuItems();
    mobileMenu.toggleMobileMenu();
    announcement.toggleActualAnnouncement();
    //foaTabs.openFirstTabAndScrollToTop();
    foaTabs.openTabFromParameter();
    return null;
}

document.onload = onLoad();

