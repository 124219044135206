import {utils} from './utils.js';

class MobileMenu {

    closeMobileMenu = () => {
        const mobileMenu = document.getElementById('mobileMenu');

        mobileMenu.style.transition = 'z-index 0s linear 300ms, opacity 300ms ease-in';
        mobileMenu.style.zIndex = '-1';
        mobileMenu.style.opacity = '0';
    }

    toggleMobileMenu = () => {
        const mobileMenu = document.getElementById('mobileMenu');
        const mobileMenuCloseButton = document.getElementById('mobileMenuCloseButton');
        const mobileMenuOpenButton = document.getElementById('mobileMenuOpenButton');

        mobileMenuOpenButton.addEventListener('click', event => {
            document.body.style.overflowY = 'scroll';
            mobileMenu.style.transition = 'opacity 300ms ease-in';
            mobileMenu.style.zIndex = '9998';
            mobileMenu.style.opacity = '1';

        })
        mobileMenuCloseButton.addEventListener('click', event => {
            document.body.style.overflow = 'auto';
            this.closeMobileMenu();
        })
    }

    addEventListenersToMobileMenuItems = () => {


        const mobileMenuItems = document.getElementsByClassName('n-mobile-menu__menu-button');

        [].forEach.call(mobileMenuItems, (el) => {
            el.addEventListener('click', event => this.scrollToElement(event, el));
            el.addEventListener('touchend', event => this.scrollToElement(event, el));
        })
    }

    scrollToElement = (event, el) => {

        const fieldOfActionTabs = document.getElementsByClassName('c-field-of-action');

        if (utils.isHomepage() === 'true') {

            event.preventDefault();

            [].forEach.call(fieldOfActionTabs, (tab) => {
                tab.classList.remove('c-field-of-action--focus');
            });

            document.getElementById(el.dataset.target).classList.add('c-field-of-action--focus');

            let headerOffset = 98;
            let elementPosition = document.getElementById(el.dataset.target).getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.scrollY - headerOffset;

            //console.log('mobile:' + elementPosition);
            //console.log('mobile:' + window.scrollY);
            //console.log('mobile:' + offsetPosition);

            if (utils.isTouchEnabled() === true || el.dataset.targetFoa === 'false' || window.scrollY > 0) {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }

            /*if (isTouchEnabled() === true || el.dataset.targetFoa === 'false' || window.scrollY > 0) {
                document.getElementById(el.dataset.target).scrollIntoView({
                    behavior: 'smooth'
                });
            }
            //el.parentNode.classList.add('c-field-of-action--focus');
            /*el.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });*/
            this.closeMobileMenu();
        }
    }
}

export let mobileMenu = new MobileMenu();