class Announcement {

    toggleActualAnnouncement = () => {

        const actualAnnouncement = document.getElementById('actualAnnouncement');
        const actualAnnouncementCloseButton = document.getElementById('actualAnnouncementCloseButton');
        const actualAnnouncementOpenButton = document.getElementById('actualAnnouncementOpenButton');

        actualAnnouncementOpenButton.addEventListener('click', event => {
            document.body.style.overflowY = 'scroll';
            actualAnnouncement.style.transition = 'opacity 300ms ease-in';
            actualAnnouncement.style.zIndex = '9999';
            actualAnnouncement.style.opacity = '1';

        })
        actualAnnouncementCloseButton.addEventListener('click', event => {
            document.body.style.overflow = 'auto';
            actualAnnouncement.style.transition = 'z-index 0s linear 1s, opacity 300ms ease-in';
            actualAnnouncement.style.zIndex = '-1';
            actualAnnouncement.style.opacity = '0';
        })
    }
}

export let announcement = new Announcement();