import {utils} from './utils.js';

class FoaTabs {

    openTabFromParameter = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const target = urlParams.get('target')
        console.log(target);
        if (target != null) {
            document.getElementById(target).classList.add('c-field-of-action--focus')
        } else {
            this.openFirstTabAndScrollToTop();
        }

    }

    openFirstTabAndScrollToTop = () => {
        const fieldOfActionHeadlines = document.getElementsByClassName('c-field-of-action__headline');
        fieldOfActionHeadlines[0].click();
    }

    addEventListenersToFieldsOfAction = () => {

        const fieldOfActionTabs = document.getElementsByClassName('c-field-of-action');
        const fieldOfActionHeadlines = document.getElementsByClassName('c-field-of-action__headline');

        [].forEach.call(fieldOfActionHeadlines, (el) => {
            el.addEventListener('click', event => scrollToElement(event, el));
        })

        function scrollToElement(event, el) {
            [].forEach.call(fieldOfActionTabs, (tab) => {
                tab.classList.remove('c-field-of-action--focus');
            });

            let headerOffset = 98;
            let elementPosition = el.parentNode.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.scrollY - headerOffset;

            //console.log('tab:' + elementPosition);
            //console.log('tab:' + window.scrollY);
            //console.log('tab:' + offsetPosition);

            el.parentNode.classList.add('c-field-of-action--focus');
            /*el.scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });*/
            if (utils.isTouchEnabled() === true || window.scrollY > 0) {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }
}

export let foaTabs = new FoaTabs();